@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Inter", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-no-warp {
  text-wrap: nowrap;
}
.text-warp {
  text-wrap: wrap;
}
/* colors */
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #f7f7fb;
}
.text-black {
  color: #000000;
}

.text-white {
  color: #ffffff;
}
.text-danger {
  color: #fc0808;
}
.text-success {
  color: #428702;
}
.text-gray {
  color: #878787;
}
.text-gray-500 {
  color: #0000007d;
}
.text-black-gray {
  color: #00000078;
}
.text-black-gray-50 {
  color: #0000007a;
}
.text-black-gray-100 {
  color: #0000008f;
}
.text-gray-light {
  color: #bdbdbd;
}
.text-primary {
  color: #29aee6;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
/* width */
.width-70 {
  width: 70%;
}
.width-30 {
  width: 30%;
}
.max-full-width {
  max-width: 100%;
}
.width-half-46 {
  width: 46%;
}
/* height */
.h-31 {
  max-height: 31px;
}
.h-34 {
  height: 34px;
}
.h-45 {
  height: 42px !important;
}
.h-18 {
  height: 18px;
}
.h-51 {
  height: 51px;
}

.h-116 {
  height: 116px;
}
.h-142 {
  height: 142px;
}
.h-299 {
  height: 299px;
}
.h-441 {
  height: 441px;
}
.max-height-687 {
  max-height: 687px;
}
.h-684 {
  height: 684px;
}
.h-705 {
  height: 705px;
}
.h-767 {
  height: 767px;
}

.select-h input {
  height: 33px !important;
}
.h-full {
  height: 100%;
}
.max-full-height {
  max-height: 100%;
}
.width-full {
  width: 100%;
}
.full-height {
  height: calc(100vh - 260px);
}
.w-15 {
  width: 15px;
}
.w-83 {
  width: 83px;
}
.w-92 {
  width: 92px;
}
.w-118 {
  width: 118px;
}
.w-142 {
  width: 142px;
}
.w-186 {
  width: 186px;
}

.w-190 {
  width: 190px;
}
.max-w-214 {
  max-width: 214px;
}
.max-w-245 {
  width: 100%;
  max-width: 245px;
}
.max-w-744 {
  max-width: 744px;
}
.w-256 {
  width: 256px;
}
.w-286 {
  width: 286px;
}
.w-316 {
  width: 316px;
}
.w-326 {
  width: 326px;
}
.w-341 {
  width: 341px;
}
.max-width-286 {
  max-width: 286px;
}
.max-width-528 {
  max-width: 528px;
}
.w-527 {
  max-width: 527px;
}
.wfull {
  width: 100%;
}
.w-528 {
  width: 528px;
}
.w-666 {
  width: 666px;
}

.w-791 {
  width: 791px;
}
.w-1074 {
  max-width: 1074px;
}
.max-width-670 {
  max-width: 670px;
}

.w-1135 {
  width: 1135px;
}
.w-2000 {
  width: 2000px;
}

.max-width-1040 {
  width: 100%;
  max-width: 1040px;
}
.max-width-1074 {
  width: 100%;
  max-width: 1074px;
}
/* margin  */
.m-0 {
  margin: 0;
}
.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-22 {
  margin-top: 22px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-22 {
  margin-top: 22px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-27 {
  margin-bottom: 27px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-17 {
  margin-top: 17px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-34 {
  margin-top: 34px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-47 {
  margin-top: 47px;
}

.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-34 {
  margin-right: 34px;
}

.mr-40 {
  margin-right: 40px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-21 {
  margin-left: 21px;
}

.ml-27 {
  margin-left: 27px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-11 {
  margin-left: 11px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-22 {
  margin-left: 22px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-32 {
  margin-left: 32px;
}

.ml-35 {
  margin-left: 35px;
}
.ml-37 {
  margin-left: 37px;
}

@media (max-width: 1400px) {
  .ml-35 {
    margin-left: 10px; /* Adjust margin-left for smaller screens */
  }
}
.ml-16 {
  margin-left: 16px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}

.mt-8 {
  margin-top: 8px;
}

/* padding */
.p-20 {
  padding: 20px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-10 {
  padding-right: 10px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-10 {
  padding-left: 10px;
}

.pr-50 {
  padding-right: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-34 {
  padding-right: 34px;
}
.pl-22 {
  padding-left: 22px;
}
.pl-34 {
  padding-left: 32px;
}
.p-8 {
  padding: 8px;
}
.p-17 {
  padding: 1;
}
.p-8 {
  padding-top: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-27 {
  padding-top: 27px;
}
.pb-40 {
  padding-bottom: 40px;
}

/* Font Size & Weight */
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}

/* scroll body */
.scroll-body {
  height: calc(100vh - 230px);
  overflow: hidden;
}

/* main section */
.area-operation-spacing {
  padding: 20px 22px;
}

.main-section-spacing {
  margin-top: 16px;
  padding: 16px;
}

.main-section-spacing-lg {
  padding: 33px 37px 77px 39px;
  margin-top: 14px;
}
.main-section-spacing-md {
  padding: 28px 54px 37px 27px;
  margin-top: 14px;
}
.main-section-spacing-sm {
  padding: 32px 23px 29px 22px;
  margin-top: 14px;
}
.inner-section-shadow {
  padding: 16px 16px 25px 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.box-shadow-all {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 4px 4px 0px #00000040; */
}
.box-shadow-all-2 {
  box-shadow: 0px 4px 4px 0px #00000040;
}
.box-shadow-all-3 {
  box-shadow: 0px 2px 10px -2px #00000040;
}

/* Input Border  */
.type-input-focus:focus {
  border-color: #2ca9dd;
}
/* .type-input-focus:not(:placeholder-shown) {
  border-color: #2ca9dd;
} */

/* input field widths */

.input-w-196 {
  max-width: 196px;
}
.max-width-700 {
  max-width: 700px;
}
.pos-fixed {
  position: fixed;
}

/* flex property */
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1 1;
}
.gap-12 {
  grid-gap: 12px;
  gap: 12px;
}
.gap-10 {
  grid-gap: 10px;
  gap: 10px;
}
.gap-14 {
  grid-gap: 14px;
  gap: 14px;
}
.gap-16 {
  grid-gap: 16px;
  gap: 16px;
}
.gap-32 {
  grid-gap: 32px;
  gap: 32px;
}
.gap-8 {
  grid-gap: 8px;
  gap: 8px;
}
.gap-24 {
  grid-gap: 20px;
  gap: 20px;
}
.gap-29 {
  grid-gap: 29px;
  gap: 29px;
}
.gap-30 {
  grid-gap: 30px;
  gap: 30px;
}

.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-item-end {
  align-items: flex-end;
}
.align-item-start {
  align-items: flex-start;
}
.justify-content-end {
  justify-content: end;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
/* borders */
.border-bottom-p {
  /* padding-bottom: 2px; */
  border-bottom: 2px solid #29aee6;
  width: -webkit-max-content;
  width: max-content;
}
.border-solid-gray {
  border: 1px solid #ced4da;
}
.border-bottom-solid-gray {
  border-bottom: 1px solid #ced4da;
}
.border-primary-color {
  border-color: #29aee6 !important;
}

/* border box radius */

.border-radius-4 {
  border-radius: 4px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-8 {
  border-radius: 8px;
}

/* Switch */
.switch-container {
  display: inline-block;
  position: relative;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  /* width: 50px;
  height: 25px; */
  width: 42px;
  height: 18px;

  /* border-radius: 25px; */
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.switch-button {
  position: absolute;
  top: 2px;
  left: 2px;
  /* width: 21px;
  height: 21px; */
  width: 22px;
  height: 14px;
  background-color: white;
  /* border-radius: 50%; */
  border-radius: 16px;
  transition: 0.3s;
}

.switch-on {
  /* left: 27px; */
  left: 18px;
  /* background-color: #4caf50; */
}
.switch-off-label-text {
  color: #ffffff;
  text-align: right;
  width: 100%;
  display: block;
  padding-right: 5px;
  /* background-color: #428702; */
  /* border: 1px solid red; */
  /* font-size: 12px; */
  box-sizing: border-box;
}
.switch-label-on {
  background-color: #4caf50;
}
.switch-label-off {
  background-color: #ccc;
}

/* MOBIO Badge */
.mo-badge-primary {
  background-color: #29aee6;
  border-radius: 10px;
  padding: 4px 10px;
  color: #ffffff;
}

/* Tables CSS */
/* table one style */
.custom-table-rm {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-rm th,
.custom-table-rm td {
  padding: 8px 12px;
  text-align: left;
}

.custom-table-rm thead th {
  font-weight: bold;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: #fcfcfc; */
  background-color: #f9f9f9;
  border-radius: 6px;
}

.custom-table-rm th.location {
  width: 20%;
}

.custom-table-rm th.service-level {
  width: 60%;
}

.custom-table-rm th.action {
  width: 20%;
}

.custom-table-rm tbody tr {
  border-bottom: 1px solid #55565a1f;
}
.custom-table-rm tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-table-rm tbody tr td:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
}
.custom-table-rm tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.custom-table-rm tbody tr:last-child {
  border-bottom: none;
}

/* table one style v2 */
.custom-table-v2 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-v2 th,
.custom-table-v2 td {
  padding: 8px 12px;
  text-align: left;
  /* border-top: 2px solid #ebf1f96b; */
  /* border-bottom: 2px solid #ebf1f96b; */
}

.custom-table-v2 thead th {
  font-weight: bold;
  font-size: 15px;
  padding-top: 26px;
  padding-bottom: 26px;
  /* background-color: #fcfcfc; */
  /* background-color: #f9f9f9; */
  /* border-radius: 6px; */
}

.custom-table-v2 tbody tr {
  /* border-bottom: 1px solid #55565a1f; */
}
.custom-table-v2 tbody tr td {
  padding-top: 26px;
  padding-bottom: 26px;
}
/* .custom-table-v2 tbody tr td:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
} */
.custom-table-v2 tbody tr td {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}
.custom-table-v2 tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.custom-table-v2 tbody tr:last-child {
  /* border-bottom: none; */
}

.custom-table-hover {
  border: 2px solid #ebf1f96b;
}
/* .custom-table-hover:hover {
  border: 2px solid #29aee6 !important;
} */

/* table one style v3 */
.custom-table-v3 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-v3 th,
.custom-table-v3 td {
  text-align: left;
  border-bottom: 2px solid #ebf1f96b;
}

.custom-table-v3 thead th {
  font-weight: 600;
  font-size: 14px;
  padding-top: 28px;
  padding-bottom: 24px;
}
.custom-table-v3 thead tr th:nth-child(1) {
  padding-left: 24px;
}
.custom-table-v3 thead tr th:last-child {
  padding-right: 24px;
}

.custom-table-v3 tbody tr {
  /* border-bottom: 1px solid #55565a1f; */
}
.custom-table-v3 tbody tr td {
  padding-top: 18px;
  padding-bottom: 17px;
}
.custom-table-v3 tbody tr td:nth-child(1) {
  padding-left: 24px;
}
.custom-table-v3 tbody tr td {
  font-size: 13px;
  font-weight: 500;
  /* padding-left: 24px; */
}
/* .custom-table-v3 tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
} */

.custom-table-v3 tbody tr:last-child {
  border-bottom: none;
}
.custom-table-v4 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures the table respects the width */
}

.custom-table-v4 th,
.custom-table-v4 td {
  text-align: left;
  border-bottom: 2px solid #ebf1f96b;
}

.custom-table-v4 thead th {
  font-weight: 600;
  font-size: 14px;
  padding-top: 28px;
  padding-bottom: 24px;
}

.custom-table-v4 thead tr th:nth-child(1) {
  padding-left: 24px;
}

.custom-table-v4 thead tr th:last-child {
  padding-right: 24px;
}

.custom-table-v4 tbody tr {
  height: auto; /* Remove fixed height if dynamic content height is needed */
}

.custom-table-v4 tbody tr td,
.custom-table-v4 tbody tr th {
  padding: 8px; /* Adjust padding as needed */
  vertical-align: middle; /* Center content vertically */
}

.custom-table-v4 tbody tr td:nth-child(1) {
  padding-left: 24px;
}

.custom-table-v4 tbody tr td {
  font-size: 13px;
  font-weight: 500;
}

.custom-table-v4 tbody tr:last-child {
  border-bottom: none;
}

/* Adjust widths dynamically */
.custom-table-v4 th,
.custom-table-v4 td {
  width: calc(100% / 8); /* Each part is 1/8th of the total width */
}

.custom-table-v4 th:nth-child(1),
.custom-table-v4 td:nth-child(1) {
  width: calc(100% / 8 * 2); /* First field gets double the width */
}

/* Add this to vertically center content in the cell */
.custom-table-v4 tbody tr td .flex {
  display: flex;
  align-items: center; /* Vertically centers items within the flex container */
  height: 100%; /* Ensures the div takes full height of the cell */
}

.custom-table-v4 tbody tr td .flex .distance-rate-table-icon {
  margin-right: 8px; /* Adjust spacing between icon and text */
}

/* Modal related Code */

.small-modal-section .MuiDialog-paperWidthSm {
  width: 540px;
  border-radius: 6px;
}
.small-modal-section .MuiDialogTitle-root {
  display: none;
}

.small-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

.delete-modal-section .MuiDialog-paperWidthSm {
  width: 617px;
  border-radius: 11px;
}
.delete-modal-section .MuiTypography-h6 {
  font-size: 17px;
  font-weight: 500;
}

.small-medium-modal-section .MuiDialog-paperWidthSm {
  width: 617px;
  border-radius: 18px;
}
.small-medium-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

.medium-modal-section .MuiDialog-paperWidthSm {
  width: 800px;
  border-radius: 18px;
}
.medium-modal-section .MuiDialogTitle-root {
  display: none;
}

.medium-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

/* leaflet */
.leaflet-container {
  height: 70vh;
  width: 100%;
  border-radius: 4px;
}

/* Pre fix and post icon in the input field */
.input_field_zone {
  /* border: 1px solid #cacaca; */
  border: 0.2px solid #000000;
  position: relative;
  border-radius: 2px;
}
.input_field_zone_post_fix {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #b7b3b3;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input_field_zone_post_fix p {
  margin: 0;
}
.input_field_zone input {
  border: none !important;
}
.input-field-increase-by {
  border: 0.2px solid #000000;
  border-radius: 2px;
  width: 119px;
  height: 27px;
  display: flex;
  align-items: center;
}
.input-field-increase-by input {
  border: none;
  width: 75%;
  outline: none;
}
.input-field-increase-by p {
  border-left: 1px solid #bdbdbd;
  padding-left: 7px;
}
/* remove arrow of the input field */
.remove-number-arrow input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* date timer  */
.react-datetime-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}
.react-datetime-picker__inputGroup__input {
  outline: none;
}

/* Scroll Bar Common Css Style */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* airport card sm lg */

.airport-detail-card-section {
  width: 241px;
  height: 69px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  border: 1px solid transparent;
  cursor: pointer;
}
.airport-detail-card-section:hover {
  border: 1px solid #29aee6;
}
.airport-detail-card-section .image-section {
  background-color: #24a7e912;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.airport-detail-card-section .text-detail {
  margin-left: 15px;
}
.airport-detail-card-section .text-detail p:nth-child(1) {
  font-size: 16px;
  font-weight: 700;
}
.airport-detail-card-section .text-detail p:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
}

/* airport card lg */
.airport-detail-card-section-lg {
  width: 241px;
  height: 206px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 38px 29px;
  border: 1px solid transparent;
  cursor: pointer;
  /* display: flex; */
}
.airport-detail-card-section-lg:hover {
  border: 1px solid #29aee6;
}
.airport-detail-card-section-lg .image-section {
  background-color: #24a7e912;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.airport-detail-card-section-lg .text-detail {
  margin-top: 18px;
}
.airport-detail-card-section-lg .text-detail p:nth-child(1) {
  font-size: 11px;
  font-weight: 500;
}
.airport-detail-card-section-lg .text-detail p:nth-child(2) {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
}

/* activity notification */
.activity-notification {
}
.activity-notification .notification-icon {
  background-color: #24a7e912;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Date Picker */
.date-picker-selection input[type="date"]::-webkit-inner-spin-button,
.date-picker-selection input[type="date"]::-webkit-clear-button {
  display: none;
}

.date-picker-selection input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 10px;
  color: transparent;
  background: none;
  z-index: 2;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
/* Formik Error Message */

.formik-error-message {
  color: #fc0808;
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* Table no Record */
.table-no-record-section {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Popover list  start */
.name-container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  padding-right: 20px;
}

.name-badge {
  background-color: #29aee624;
  color: #1b94c7;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 600;
}

.remaining-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #2ca9dd;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

.remaining-modal {
  position: absolute;
  top: 44px;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap;
}

/* Button V2 */
.mo2-button:disabled {
  background-color: #2ca9dd;
  opacity: 0.6;
}

/* Tooltip */
/* Tooltip content style */
.primary-tooltip .MuiTooltip-tooltip {
  background-color: #000; /* Change background color */
  color: #fff; /* Change text color */
  font-size: 14px; /* Font size */
  padding: 10px; /* Padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Tooltip arrow style */
.primary-tooltip .MuiTooltip-arrow {
  color: #000; /* Same color as background to make the arrow match */
}

.tooltip-icon-position {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* clock timer */
.clock-timer {
  position: relative;
  border: 0.2px solid #000000;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.clock-timer-input {
  outline: none;
  border: none;
  width: 100%;
}

.clock-timer-active {
  z-index: 999999;
}

.clock-timer-inactive {
  z-index: 0;
}

.clock-options {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  width: 142px;
  /* height: 210px; */
  /* padding: 0.625rem; */
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 10px -2px #00000040;
  overflow: hidden;
}

.clock-options-visible {
  z-index: 20;
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
  visibility: visible;
  opacity: 1;
  /* display: block; */
}

.clock-options-hidden {
  /* display: none; */
  -webkit-transform: translateY(1rem);
          transform: translateY(1rem);
  visibility: hidden;
  opacity: 0;
}

.clock-options-hours,
.clock-options-minutes {
  /* flex: 1; */
  cursor: pointer;
  width: 40px;
  height: 102px;
  overflow: auto;
}
.clock-options-hours::-webkit-scrollbar,
.clock-options-minutes::-webkit-scrollbar {
  width: 0px;
}

.clock-option {
  /* padding: 6px; */
  padding-top: 6px;
  padding-bottom: 6px;
  color: #323232;
  font-size: 14px;
  text-align: center;
}
.clock-option:hover {
  color: #2ca9dd;
  border-radius: 4px;
  background-color: #29aee624;
}

.clock-option.selected {
  /* background-color: #f0f0f0; */

  color: #2ca9dd;
  font-weight: bold;
  font-size: 16px;
}

.clock-icon {
  position: absolute;
  right: 1rem; /* Tailwind's right-4 */
  top: 0.875rem; /* Tailwind's top-3.5 */
  height: 13px; /* Adjust as needed */
  width: 13px; /* Adjust as needed */
}

.clock-timer-cancel {
  border: none;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.29px;
  color: #1c1c1c;
  background-color: transparent;
  padding: 5px 11px;
  cursor: pointer;
  border-radius: 2px;
}
.clock-timer-cancel:hover {
  border: 1px solid gray;
  background-color: gray;
  color: white;
}
.clock-timer-save {
  border: 0.6px solid #29aee6;
  border-radius: 2px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.29px;
  background-color: transparent;
  color: #29aee6;
  cursor: pointer;
  padding: 5px 11px;
}
.clock-timer-save:hover {
  background-color: #29aee6;
  color: white;
}
/* Date Time Picker Components */
.date-picker-v2 .react-datepicker {
  border: none !important;
  font-family: Inter;
}
.date-picker-v2 .react-datepicker__month-container {
  width: 275px;
  min-height: 270px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px -2px #00000040;
  border: none !important;
}
.date-picker-v2 .react-datepicker__header {
  margin-top: 20px;
  background-color: transparent;
  border: none !important;
}
.date-picker-v2 .react-datepicker__current-month {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 12.1px;
  color: #000000;
  width: 70%;
  margin: 0px auto;
  border-bottom: 1px solid #00000012;
  padding-bottom: 11px;
}
.date-picker-v2 .react-datepicker__navigation {
  margin-top: 16px;
  padding-bottom: 11px;
}
.date-picker-v2 .react-datepicker__day-names {
  margin-top: 14px;
}
.date-picker-v2 .react-datepicker__day-name {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 13.31px;
  color: #323232;
  /* margin: 4px 5px; */
}

.date-picker-v2 .react-datepicker__day:hover {
  /* margin: 4px 5px; */
  background-color: #2ca9dd;
  color: #ffffff !important;
  border-radius: 50%;
}
.date-picker-v2
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.date-picker-v2
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  content: none !important;
  display: none !important;
}
.date-picker-v2 .react-datepicker__month {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.31px;
}
.date-picker-v2 .react-datepicker__day--selected,
.date-picker-v2 .react-datepicker__day--keyboard-selected,
.date-picker-v2 .react-datepicker__day--keyboard-selected:hover {
  background-color: #2ca9dd;
  color: #ffffff !important;
  border-radius: 50%;
}

.sun-sat-highlight {
  color: #2ca9dd !important;
}

.date-picker-v2 .react-datepicker__navigation-icon--previous::before {
  content: none;
  display: none;
}
.date-picker-v2 .react-datepicker__navigation-icon--next::before {
  content: none;
  display: none;
}
.date-picker-v2 .react-datepicker__navigation--next {
  background: url(/static/media/calendar_arrow-right.e9710e5a.svg) no-repeat;
  border: none;
  right: 14px;
}
.date-picker-v2 .react-datepicker__navigation--previous {
  background: url(/static/media/calendar_arrow-left.65e0ce7f.svg) no-repeat;
  border: none;
  left: 14px;
}

/* pagination  Start ----------------------- */
.pagination {
  display: flex;
  align-items: center;
  list-style: none;
}

.pagination > .active > a {
  border: 1px solid #005bbf;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #f5f5f5 !important;
  color: #333333 !important;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 400;
}

.pagination > li > a:hover {
  border: 1px solid #005bbf;
}
.pagination > li > a {
  display: block;
  border: 1px solid transparent;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #009ef7;
  background-color: rgb(255, 255, 255);
}
.pagination .break > a:hover {
  border: 1px solid transparent;
}
.pagination .break > a {
  background-color: transparent !important;
  font-size: 14px;
  height: 100%;
  padding: 0px;
}
.pagination .next > a,
.pagination .previous > a {
  display: block;
  background-color: transparent !important;
  height: 100%;
  padding: 0px;
}
/* pagination  End ----------------------- */

/* sorting */
.sorting-hover:hover {
  color: #48b3e8;
}

/* Aside Menu CSS  */
.aside-bar-menu-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 300px);
}
.aside-bar-menu-section-first {
  flex: 1 1;
}
.itemMenuTitle .MuiTypography-body1 {
  font-size: 16px;
  font-weight: 700;
}

/* Booking card */
.booking-card-more-section .MuiIconButton-root {
  padding: 0 16px 0 0;
}
.booking-card-more-section .Mui-expanded {
  margin-right: 0px;
  /* padding-right: 16px; */
}

.booking-card-more-section .MuiAccordionSummary-expandIcon.Mui-expanded {
  -webkit-transform: none;
          transform: none;
}

/* Customer User Profile */
.add-user-customer-section .MuiDialog-paperWidthSm {
  width: 900px;
}

/* Dropdown Button */
.dropdown-menu-section {
  position: relative;
}

.dropdown-menu-section > button {
  all: unset;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.dropdown-menu-section .dropdown-menu-horizontal {
  padding: 3px 10px 3px 10px !important;
  background-color: #eef2f8;
  border-radius: 4px;
}
.dropdown-menu-list {
  box-sizing: border-box;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* width: 140px; */
  /* width: 167px; */
  min-width: 167px;
  position: absolute;
  top: 30px;
  right: 50%;
  /* right: 100px; */
  background-color: #fff;
  box-shadow: 2px 5px 10px #b7b3b3;
  z-index: 60;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dropdown-menu-list button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* text-align: center; */
  cursor: pointer;
  width: 100%;
  /* padding: 4px 10px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 13px;
  font-weight: 500;
  text-wrap: nowrap;
  color: #777e90;
  text-transform: uppercase;
  /* border: 1px solid red; */
  margin-bottom: 10px;
}
.dropdown-menu-list button:hover {
  background-color: #e9e9ea !important;
}

.dropdown-menu-list button:hover {
  background-color: #ffffff;
}
.dropdown-menu-list .disable-btn {
  background-color: #f5f5f5;
  color: #a9a9a9;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
.dropdown-menu-list .tooltip-section {
  position: relative;
}
.dropdown-menu-list .tooltip-section .tab-selection-tooltip-icon {
  top: 4px;
}

.dropdown-menu-active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
  visibility: visible;
  padding: 13px 13px 0px 13px;
}
.dropdown-menu-active-commission {
  width: 167px;
  height: 88px;
  padding: 13px 0px 0px 0px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 6px 0px 0px 0px;
  opacity: 0px;
  box-shadow: 0px 22px 57px 0px rgba(0, 0, 0, 0.3);
}

.dropdown-menu-active-commission-field {
  max-width: 141px;
  max-height: 26px;
  display: flex;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 13px;
  padding: 2px 2px 2px 2px;
}
.dropdown-menu-active-commission-field button:hover {
  background-color: #ffffff;
}

.dropdown-menu-list-commission {
  box-sizing: border-box;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* width: 140px; */
  position: absolute;

  right: 100px;
  background-color: #fff;

  z-index: 60;
}

.dropdown-menu-list-commission button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* text-align: center; */
  cursor: pointer;
  width: 100%;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: 500;
  text-wrap: nowrap;
  color: #777e90;
  text-transform: uppercase;
}

.dropdown-menu-list-commission button:hover {
  background-color: #ffffff;
}

.square-shape {
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  rotate: 45deg;
  position: absolute;
  top: -13px;
  right: 6px;
  box-shadow: -4px -1px 4px #b7b3b3;
  z-index: -9;
}
.square-shape::after {
  content: "";
  position: absolute;
  top: 0px;
  right: -1px;
  background-color: #ffffff;
  width: 32px;
  height: 33px;
  rotate: 0deg;
  z-index: -9;
}
.dropdown-menu-hide {
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  opacity: 0;
  visibility: hidden;
}
/* Toggle button section */
.btn-toggle-section .toggle-manage-section {
  border-radius: 26px;
  /* height: 50px; */
  border: 1px solid #29aee6;
  width: -webkit-max-content;
  width: max-content;
  padding: 6px;
}
.btn-toggle-section .toggle-manage-section button {
  border: none;
  background-color: transparent;
  padding: 12px 46px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  border-radius: 26px;
}
.btn-toggle-section .toggle-manage-section .active-toggle-btn {
  background-color: #29aee6;
  color: #ffffff;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.btn-toggle-section .toggle-manage-section .in-active-toggle-btn {
  background-color: transparent;
  color: #29aee6;
  transition: background-color 0.5s ease, color 0.5s ease;
}

/* Customer Setting */
.customer-setting-section .MuiFormControl-root {
  height: 61px !important;
}
.customer-setting-section .MuiOutlinedInput-root {
  height: 61px !important;
  border: 2px solid #858585;
}

/* Dropdown for commission rules */
.dropdown-commission-rules .dropdown-commission-rules {
  width: 167px;
  height: 88px;
}

/* customer- */
.customer-contact-us-section .MuiFormControl-root {
  height: 257px !important;
  width: 545px !important;
}
.customer-contact-us-section .MuiOutlinedInput-root {
  height: 257px !important;
  width: 545px !important;
}

/* Third Party API Connection */
.thirdPartyConnection-main {
  background-color: #ffffff;
  border-radius: 5px;
  width: 370px;
}
.tpc-header-section {
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 18px;
  padding-right: 18px;
  border-bottom: 0.5px solid #bdbdbd4d;
  position: relative;
  height: 45px;
}
.tpc-header-icon {
  position: absolute;
  top: 20px;
  left: 18px;
}

.tpc-body-main {
  padding-top: 40px;
  padding-bottom: 44px;
  height: 112px;
}

.tpc-footer-main {
  border-top: 0.5px solid #bdbdbd4d;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 11px;
  padding-bottom: 15px;
}
.input_field_auto_drive{
   position: relative;
}
.input_field .input-field-styles,
.input_field select.optionStyle {
  font-size: 13px;
  color:  #000 !important;
  line-height: 18px;
  position: relative
}

.redPencilStyle {
  position: absolute;
  top:-10,
}

/* Input Fields */
.input_field label {
  font-size: 14px;
  display: block;
}
.input_field input {
  all: unset;
  /* border: 1px solid #cacaca; */
  /* border: 0.5px solid #000000; */
  /* border: 0.2px solid #000000; */
  /* border: 0.5px solid #00000059; */
  /* border: 0.5px solid #000000; */
  border: 0.5px solid #00000059;
  font-size: 14px;
  text-indent: 10px;
  color: #555;
  height: 32px;
  width: 100%;
  border-radius: 2px;
}
.input-field-sm label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  display: block;
}
.input-field-sm input {
  all: unset;
  /* border: 1px solid #cacaca; */
  border: 0.2px solid #000000;
  font-size: 14px;
  text-indent: 10px;
  color: #555;
  height: 26px;
  width: 100%;
  border-radius: 2px;
}
.input-field-sm select {
  /* all: unset; */
  outline: none;
  /* border: 1px solid #cacaca; */
  border: 0.5px solid #000000;
  font-size: 14px;
  font-weight: 400;
  /* text-indent: 10px; */
  color: #6e6e6e;
  height: 28px;
  width: 100%;
  border-radius: 2px;
  /* padding: 7px 11px; */
}

.input-field-commission label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  display: block;
}
.input-field-commission input {
  all: unset;
  /* border: 1px solid #cacaca; */
  border: 0.2px solid #000000;
  background: #ffffff;
  color: #bdbdbd;

  height: 34px;
  width: 305px;
  border-radius: 2px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
}
.input-field-commission select {
  /* all: unset; */
  outline: none;
  /* border: 1px solid #cacaca; */
  border: 0.5px solid #000000;
  font-size: 14px;
  font-weight: 400;
  /* text-indent: 10px; */
  color: #6e6e6e;
  height: 31px;
  width: 100%;
  border-radius: 3px;
  padding: 7px 11px;
}
.input_field_commission label {
  font-size: 14px;
  display: block;
}
.input_field_commission input {
  all: unset;
  /* border: 1px solid #cacaca; */
  /* border: 0.5px solid #000000; */
  border: 0.2px solid #000000;

  color: #555;
  height: 32px;
  width: 667px;
  border-radius: 2px;
  padding-left: 13px;
  font-size: 12px;
  font-weight: 400;
}

.input_field_commission select {
  /* all: unset; */
  width: 667px;
  height: 34px;
  top: -0.25px;
  left: -0.2px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  border: 0.2px 0px 0px 0px;
  opacity: 0px;
  border: 0.2px solid #000000;
  background: #ffffff;
  padding-left: 13px;
  color: #bdbdbd;
}
.input_field_commission textarea {
  all: unset;
  outline: none;
  border: 1px solid #cacaca;
  font-size: 12px;
  text-indent: 10px;
  color: #555;
  /* height: 32px; */
  width: 100%;
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.input_field select {
  /* all: unset; */
  outline: none;
  border: 1px solid #cacaca;
  font-size: 14px;
  text-indent: 10px;
  color: #555;
  /* height: 32px; */
  width: 100%;
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
}


.input-field-v2 select {
  /* all: unset; */
  outline: none;
  /* border: 1px solid #cacaca; */
  border: 0.5px solid #00000059;

  font-size: 13px;
  /* text-indent: 10px; */
  color: #555;
  height: 39px;
  width: 100%;
  border-radius: 2px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  padding-left: 8px;
  padding-right: 14px;
}

/*md-lg  */
.input-field-md-lg label {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 12px;
  display: block;
}
.input-field-md-lg input {
  all: unset;
  /* border: 1px solid #cacaca; */
  border: 0.2px solid #00000059;
  /* border: 0.2px solid #2ca9dd; */
  font-size: 15px;
  font-weight: 400;
  text-indent: 10px;
  color: #7b7b7b;
  height: 45px;
  width: 100%;
  border-radius: 2px;
}

/* input lg */
.input-field-lg label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  display: block;
}
.input-field-lg select {
  /* all: unset; */
  height: 45px;
  outline: none;
  border: 0.5px solid #000000;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  width: 100%;
  border-radius: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 14px;
}
.input-field-lg input {
  all: unset;
  /* border: 1px solid #cacaca; */
  /* border: 0.2px solid #000000; */
  border: 0.2px solid #000000;
  font-size: 15px;
  font-weight: 400;
  text-indent: 10px;
  color: #7b7b7b;
  height: 45px;
  width: 100%;
  border-radius: 2px;
}

.input_field textarea {
  all: unset;
  outline: none;
  border: 1px solid #cacaca;
  font-size: 12px;
  text-indent: 10px;
  color: #555;
  /* height: 32px; */
  width: 100%;
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.input_field_mb_12 {
  margin-bottom: 8px;
}
.error-text-danger {
  color: #ea6464;
}
.error-text-style {
  margin-left: 6px;
  font-size: 12px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 10px;
  border-radius: 10px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 16px 16px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

/* Components */

.header-description-section h1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  color: #000000;
}
.header-description-section p {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
  margin-top: 8px;
  color: #878787;
}

/* Rate API */

/* Main Screen */

/* breadcrumb */
.breadcrumb {
  font-size: 16px;
  font-weight: 500;
  line-height: 16.94px;
}

.breadcrumb-link-blue {
  color: #48b3e8;
}

.breadcrumb-current-black {
  color: black;
}
.breadcrumb-description {
  margin-top: 4px;
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #878787;
}
.breadcrumb-tooltip-status {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
}
.breadcrumb-tooltip .tab-selection-tooltip-icon {
  right: -24px;
}
/* searching input section style */
.searching-input-section {
  background-color: #ffffff;
  width: 235px;
  /* height: 30px; */
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 12px;
}
.searching-input-section input {
  border: none;
  outline: none;
  margin-left: 8px;
  font-size: 12px;
}
/* add location page */
.select-country-box-disabled {
  position: relative;
  z-index: 0;
}
.select-inner-country-box-disabled {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
}
.select-location-field .stdropdown-container {
  border-radius: 2px;
  border: 0.5px solid #000000;
}
.select-location-field .stdropdown-container:hover {
  border: 0.5px solid #29aee6;
}
.select-location-field .stdropdown-item span {
  margin-right: 8px;
}
.select-location-field .stdropdown-item {
  font-family: "Inter", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
}
.tab-selection-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 18px;
}
.tab-selection-section .tab-title {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab-selection-section .active {
  background-color: #1c92c1;
  color: #ffffff;
}
.tab-selection-section .deactive {
  background-color: #d9d9d9;
  color: #000000;
}
.tab-selection-section .tab-line {
  width: 113px;
  border: 1px dashed #000000;
  height: 2px;
  margin-left: 10px;
  margin-right: 10px;
}
/* .country-city-modal .MuiDialog-paper  {
  overflow: visible;
} */
.country-city-modal .MuiDialog-paper {
  overflow: visible !important;
}
.country-city-modal .MuiDialogContent-root {
  overflow: visible;
}
.country-city-modal .MuiDialog-paperScrollPaper {
  width: 617px;
  /* height: 220px; */
}
.select-country-list-field .css-1xc3v61-indicatorContainer {
  color: #00000061;
}
.select-country-list-field .css-13cymwt-control {
  border: 0.5px solid #00000061;
  border-radius: 2px;
}
.select-country-list-field .css-t3ipsp-control:hover {
  border: 0.5px solid #29aee6;
  border-radius: 2px;
  box-shadow: none;
}

.add-location-section {
  display: flex;
  height: 100%;
}
.add-location-section .input-label {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  margin-bottom: 6px;
}
.add-location-section .stsearch-box input {
  border: none;
  outline: none;
}

.add-location-left-panel {
  height: 100%;
  flex: 1 1;
  /* padding: 20px; */
}
.add-location-left-panel-inner-section {
  height: 100%;
  border-right: 1px solid #00000040;
}

.right-panel {
  flex: 1 1;
}
.airportcityhead {
  margin-bottom: 0;
  margin-left: 20px;
}
.right-panel-content {
  margin-left: 20px;
}
.airportdesc {
  margin-top: 5px;
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 12;
  color: #878787;
}
.airport-list {
  margin-left: 20px;
}

.airport {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.airport input[type="radio"] {
  margin-right: 5px;
  vertical-align: middle;
  -webkit-transform: scale(2);
          transform: scale(2);
  margin-left: 20px;
  width: 11px;
  height: 11px;
}

.airport label {
  margin-left: 13px !important;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  margin: 0;
}

.add-location-parent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 22px;
}
.location-country-detail {
  margin-top: 8px;
  display: flex;
  align-items: center;
  grid-gap: 18px;
  gap: 18px;
}
.location-country-detail p {
  font-size: 20px;
  color: #428702;
}
.add-location-iata-search {
  border-bottom: 1px solid #00000040;
  position: relative;
  margin-bottom: 24px;
  padding-top: 12px;
}
.add-location-iata-search p {
  margin: 0;
  position: absolute;
  top: 0;
  left: 45%;
  background-color: #ffffff;
  padding-left: 8px;
  padding-right: 8px;
}

/*Location And Rate Card*/
.rate-city-main-body {
  display: grid;
  grid-gap: 14px;
  gap: 14px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.city-page-section {
  box-shadow: 0px 1px 10px -2px #00000040;
  background-color: #ffffff;
  border-radius: 2px;
  /* width: 258px; */
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  cursor: pointer;
}
.city-card-section-disable {
  box-shadow: none;
  background-color: #dddd;
}
.city-page-section:hover {
  border: 1px solid #29aee6;
}

.city-page-section .city-name h1 {
  margin-left: 16px;
  font-weight: 500;
  font-size: 11px;
}

.city-page-section .actions {
  display: flex;
  align-items: center;
}

.city-page-section .manage {
  cursor: pointer;
  margin-right: 50px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #29aee6;
}

.city-page-section .detailsIcon {
  color: #34abe6;
  font-size: 20px;
  cursor: pointer;
}
.edit-location-zone-map-section {
  /* height: "400px";
  width: "100%"; */
  position: relative;
}
.zone-map-section-form {
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 5;
}
.area-of-operation-lead-time-field {
  border: 0.5px solid #00000059;
  height: 45px;
  border-radius: 2px;
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.area-of-operation-lead-time-field select {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.area-of-operation-lead-time-field p {
  color: #878787;
}
.area-operation-lead-time {
  width: 100%;
}
.area-operation-lead-time .react-datetime-picker__wrapper {
  /* border: 0.2px solid #000000; */
  border: 0.5px solid #00000059;
  height: 45px;
  border-radius: 2px;
}
.area-operation-central-zone-field {
  height: 45px;
  border: 0.5px solid #00000059;
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
}
.area-operation-central-zone-field input {
  border: none;
  outline: none;
  width: 100%;
}
.edit-location-field label {
  font-size: 9px;
  font-weight: 500;
  margin-bottom: 6px;
}
.edit-location-field input {
  border: 0.5px solid #00000029;
  height: 28px;
  font-size: 12px;
  font-weight: 400;
}
.far_central_zone_field {
  /* border: 1px solid #cacaca; */
  position: relative;
  border-radius: 2px;
  border: 0.5px solid #00000029;
}
.far_central_zone_field_post_fix {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #00000029;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.far_central_zone_field_post_fix p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}
.far_central_zone_field input {
  border: none;
}
/* city airpoty style */
.city-airport-status-btn {
  background-color: #ebf1f96b;
  border: none;
  padding: 10px 19px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
}
.city-airport-status-btn-active {
  color: #003399;
}
.city-airport-status-btn-inactive {
  color: #777e90;
}
.city-airport-status-section {
  padding: 21px 22px;
}
.city-airport-table-list table thead tr th:nth-child(1),
.city-airport-table-list table tbody tr td:nth-child(1) {
  padding-left: 29px;
}
/* country list */
.rate-country-main-body {
  display: grid;
  grid-gap: 14px;
  gap: 14px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.country-card-section {
  /* width: 312px; */
  padding: 14px 16px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0px 1px 10px -2px #00000040;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box !important;
}
.country-card-section:hover {
  border: 1px solid #29aee6;
}
.country-card-section-disable {
  box-shadow: none;
  background-color: #dddd;
}
.country-name-section {
  margin-left: 20px;
}
.country-name-section h1 {
  font-size: 14px;
  font-weight: 600;
}

/* search large size */
.search-lg-section {
  background-color: #f1f1f1;
  width: 60%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}
.search-lg-section input {
  margin-left: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
}

/*Integration connection */
.integration-connection-section {
  margin-top: 38px;
  background-color: #24a7e912;
  padding: 63px 100px;
}
.inner-integration-section {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 34px 43px 131px 69px;
}

/*Integration Services */
.integration-error-custom-toast .Toastify__toast-body {
  width: 32px !important;
}
.integration-error-custom-toast .Toastify__toast-icon {
  width: 32px !important;
}
.integrationServicesCard-main-section {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.IntegrationServicesCard {
  /* width: 366px; */
  /* min-height: 108px; */
  min-height: 128px;
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: 22px;
  padding-left: 29px;
  padding-right: 35px;
  cursor: pointer;
  /* box-shadow: 0px 7px 14px 0px #d3dae2; */
  box-shadow: 0px 4px 17px -1px #00000040;

  border: 1.5px solid transparent;
  position: relative;
}
/* .IntegrationServicesCard:hover {
  border: 1.5px solid #29aee6;
} */
.IntegrationServicesCard-filled:hover {
  border: 1.5px solid #29aee6;
}
.IntegrationServicesCard-not-complete {
  border: 1.5px solid #db4446;
}
.IntegrationServicesCard-not-complete-warning {
  border: 1.5px solid #ffc107;
}

.IntegrationServicesCard .card-content {
  display: flex;
  align-items: flex-start;
}
.IntegrationServicesCard .card-content svg {
  margin-top: 4px;
  width: 25px;
  height: 25px;
}
.IntegrationServicesCard .card-content .card-content-icon {
  /* border: 1px solid red; */
}
.IntegrationServicesCard .card-content .MuiSvgIcon-root {
  color: #29aee6;
}

.IntegrationServicesCard .text-content {
  margin-left: 18px;
}
.IntegrationServicesCard .card-content-icon {
  width: 20px;
  height: 25px;
}

.IntegrationServicesCard .heading {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
  color: #000000;
  padding-bottom: 6px;
  border-bottom: 0.5px solid #0000001a;
  width: 100%;
}

.IntegrationServicesCard .description {
  margin: 0;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #878787;
  overflow: hidden;
}
.integration-services-card-help {
  position: absolute;
  top: 18px;
  right: 12px;
}

/* Rate manage page css */
/* .custom-table-rm {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-rm th,
.custom-table-rm td {
  padding: 8px 12px;
  text-align: left;
}

.custom-table-rm thead th {
  font-weight: bold;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fcfcfc;
}

.custom-table-rm th.location {
  width: 20%;
}

.custom-table-rm th.service-level {
  width: 60%;
}

.custom-table-rm th.action {
  width: 20%;
}

.custom-table-rm tbody tr {
  border-bottom: 1px solid #55565a1f;
}
.custom-table-rm tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-table-rm tbody tr td:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
}

.custom-table-rm tbody tr:last-child {
  border-bottom: none;
} */
.rm-service-leave-section p {
  /* width: 46px;
  height: 21px; */
  margin: 0;
  margin-right: 30px;

  background-color: #91c5611f;

  padding: 2.5px 19px;
  color: #428702;
  border-radius: 20px;
  font-size: 14px;
}
.rm-service-leave-badge {
  padding: 6px 18px 6px 18px;
  background-color: #29aee624;
  color: #1b94c7;
  font-size: 9px;
  font-weight: 600;
  border-radius: 20px;
}
.fr-modal-section-detail {
  margin-top: 12px;
  margin-bottom: 36px;
}
.AddCommission .MuiDialog-paperWidthSm {
  max-width: 744px;
  height: 613px;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
}
.rm-fr-edit {
  /* color: #29aee6;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: max-content; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border: 1px solid red; */
}

.fr-modal-section .MuiDialog-paperScrollPaper {
  width: 1055px;
  /* height: 817px; */
  /* height: 758px; */
}
.fr-modal-section .MuiDialog-paperWidthSm {
  max-width: 1055px !important;
}

.airport-service-level-edit-modal .MuiDialog-paperScrollPaper {
  width: 744px;
  min-height: 469px;
}
.airport-service-level-edit-modal .MuiDialog-paperWidthSm {
  max-width: 744px !important;
}
.airport-service-level-edit-modal .MuiDialog-paper,
.airport-service-level-edit-modal .MuiDialogContent-root {
  overflow-y: visible;
}

.add-commission-modal .MuiDialog-paperScrollPaper {
  width: 550px;
  min-height: 469px;
}
.add-commission-modal .MuiDialog-paperWidthSm {
  max-width: 550px !important;
}
.add-commission-modal .MuiDialog-paper,
.add-commission-modal .MuiDialogContent-root {
  overflow-y: visible;
}

.fr-model-section-rate-form-section {
  display: flex;
  align-items: start;
}
.rate-form-section {
  border-right: 1px solid #8787874d;
  padding-right: 57px;
}
.rate-to-section {
  padding-left: 57px;
}
.fr-model-section-rate-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fr-model-section-service-field label {
  font-size: 15px;
  font-weight: 500;
  margin-left: 8px;
}

.fr-model-section-rate-field {
  width: 150px;
  height: 30px;
  border: 1px solid #29aee6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
}
.fr-model-section-rate-field-pre-fix {
  border-right: 2px solid #b7b3b3;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fr-model-section-rate-field-pre-fix p {
  font-size: 17px;
  font-weight: 500;
  color: #878787;
}
.fr-model-section-rate-field input {
  border: none;
  outline: none;
  width: 80px;
  padding-left: 8px;
  font-size: 17px;
  font-weight: 500;
  color: #878787;
}

.distance-rate-table-icon {
  width: 36px;
  height: 25px;
  object-fit: cover;
  margin-right: 9px;
}
.airport-service-level-fields input,
.airport-service-level-fields select {
  outline: none;
  border: 0.2px solid #000000;
  background: #ffffff;
  height: 34px;
  width: 100%;
  border-radius: 2px 0px 0px 0px;
  padding-left: 10px;
}
.airport-service-level-time-field {
  width: 100%;
}
.airport-service-level-time-field .react-datetime-picker__wrapper {
  border: 0.2px solid #000000;
  height: 33px;
  border-radius: 2px;
}
.distance-model-pre-fix-field {
  /* border: 0.5px solid #cacaca; */
  border: 0.5px solid #00000059;
  width: 102px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 6px;
}
.distance-model-pre-fix-field .pre-fix-title {
  border-right: 0.5px solid #00000012;
}
.distance-model-pre-fix-field .post-fix-title {
  border-left: 0.5px solid #00000012;
}
.distance-model-pre-fix-field .pre-fix-title p {
  font-size: 11px;
  font-weight: 400;
  color: #00000063;
  padding-right: 6px;
}
.distance-model-pre-fix-field .post-fix-title p {
  font-size: 11px;
  font-weight: 400;
  color: #00000063;
  padding-left: 6px;
}
.distance-model-pre-fix-field input {
  border: none;
  outline: none;
  width: 100%;
  padding-left: 6px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
}
.distance-rate-check-post {
  height: 39px;
  width: 120px;
}
.distance-model-other-rates-section {
  border-top: 1px solid #8787874d;
  margin-top: 34px;
  margin-bottom: 22px;
}
.distance-rate-edit-modal .MuiDialog-paper {
  overflow: visible;
}
.distance-check-rate-modal .MuiDialog-paperScrollPaper {
  width: 651px;
  /* height: 404px; */
}

.servicelevel-rate-edit-modal .MuiDialog-paper {
  overflow: visible;
}
.servicelevel-check-rate-modal .MuiDialog-paperScrollPaper {
  width: 651px;
  /* height: 404px; */
}

.check-model-your-rate {
  border-top: 1px solid #00000012;
  margin-top: 26px;
  padding-top: 15px;
  margin-bottom: 16px;
}
.check-modal-initial-rate {
  border-top: 1px solid #00000012;
  padding-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.hourly-rate-edit-modal .MuiDialog-paper {
  overflow: visible;
}
.hourly-rate-edit-modal .MuiDialog-paperScrollPaper {
  width: 549px;
  height: 317px;
}
.hourly-rate-field-rate-section {
  /* width: 228px; */
  width: 100%;
  height: 30px;
  /* border: 0.2px solid #cacaca; */
  border: 0.2px solid #000000;
  border-radius: 2px;
  padding-right: 10px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.hourly-rate-field-rate-section input {
  border: none;
  outline: none;
  width: 30px;
  margin-left: 10px;
  /* padding-left: 4px; */
  /* border: 1px solid red; */
}

.hourly-time-picker {
  width: 100%;
}
.hourly-time-picker .react-datetime-picker__wrapper {
  border: 0.2px solid #000000;
  height: 27px;
  border-radius: 2px;
  padding-left: 13px;
}

.hourly-rate-field-rate-section button {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.hourly-rate-field-rate-section button:nth-child(1) {
  color: #428702;
}
.hourly-rate-field-rate-section button:nth-child(1) {
  color: #fc0808;
}
.hourly-rate-edit-btn button {
  margin-top: 23px;
  width: 100%;
}

.airport-pickup-fee-section {
  margin-top: 16px;
  padding: 33px 39px 26px 39px;
}
.airport-pickup-status-section {
  /* padding: 21px 22px; */
  padding-bottom: 54px;
}
.airport-pickup-status-btn {
  background-color: #ebf1f96b;
  border: none;
  padding: 10px 19px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
}
.airport-pickup-status-btn-active {
  color: #003399;
}
.airport-pickup-status-btn-inactive {
  color: #777e90;
}
.airport-rate-header-section {
  border-bottom: 1px solid #00000017;
  padding-bottom: 32px;
  margin-bottom: 26px;
}
.airport-rate-service-image {
  width: 58.14px;
  height: 40px;
  margin-right: 18px;
}
.airport-fee-rate-field-all {
  width: 118px;
  height: 27px;
  border: 0.2px solid #000000;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 3px 5px;
}

.airport-fee-rate-field-all-pre-fix {
  border-right: 2px solid #b7b3b3;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.airport-fee-rate-field-all-pre-fix p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.airport-fee-rate-field-all input {
  border: none;
  outline: none;
  width: 50px;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.airport-fee-rate-field {
  /* width: 100%; */
  width: 295px;
  height: 34px;

  /* border: 1px solid #b7b3b3; */
  border: 0.2px solid #000000;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 2px 17px;
}
.airport-fee-rate-field-pre-fix {
  border-right: 1px solid #b7b3b3;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.airport-fee-rate-field-pre-fix p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #00000054;
}
.airport-fee-rate-field input {
  border: none;
  outline: none;
  width: 100%;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.airport-fee-right-section {
  /* width: 190px; */
}
.airport-fee-notes-section {
  width: 408px;
  text-align: right;
}

/* Lead time */

.lead-prior-time-picker {
  width: 100%;
}
.lead-prior-time-picker .react-datetime-picker__wrapper {
  border: 0.2px solid #000000;
  height: 27px;
  border-radius: 2px;
  padding-left: 8px;
}

.lead-time-modal-section .MuiDialog-paper {
  overflow: visible;
}

.lead-time-modal-section .MuiDialog-paperWidthSm {
  /* max-width: 800px; */
}
.lead-time-modal-section .MuiDialog-paperScrollPaper {
  width: 549px !important;
  /* height: 230px; */
}

.rate-lead-time-field {
  border: 0.5px solid #00000059;
  height: 27px;
  border-radius: 2px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rate-lead-time-field select {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.rate-lead-time-field p {
  color: #878787;
}

.add-lead-time-section {
  margin-top: 12px;
  /* padding-top: 28px;
  padding-bottom: 41px;
  margin-bottom: 28px; */
  /* border-top: 1px solid #00000017;
  border-bottom: 1px solid #00000017; */
}

/* pickup-instruction style */
.date-picker-selection-v2 {
  height: 26px;
  width: 70%;
  position: absolute;
  top: 6px;
  left: 4px;
}
.date-picker-selection-v2 .react-datepicker__input-container input {
  width: 100%;
  border: none;
  outline: none;
}

.pickup-instruction-main-section {
  display: flex;
}
.pickup-instruction-left-side {
  border-right: 1px solid #0000002e;
  padding-right: 26px;
}
.pick-up-instruction-name {
  padding-bottom: 20px;
  margin-bottom: 21px;
  border-bottom: 1px solid #0000002e;
}
.pick-up-instruction-name input {
  background-color: #f5f5f5;
  height: 38px;
  outline: none;
  border: none;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  text-indent: 14px;
  margin-top: 22px;
}
.pickup-instruction-right-side {
  padding-left: 18px;
}

.pickup-instruction-select-box .css-1293zam-control {
  min-height: 34px;
  border: 0.5px solid #000000;
  border-radius: 2px !important;
}
.pickup-instruction-select-box .css-f1vmnv-control {
  border-radius: 2px !important;
}
.pickup-instruction-select-box .css-1cokigs-control {
  border: 1px solid red;
  height: 31px !important;
  padding-top: 0px !important;
}
.pickup-instruction-select-box .css-1u9des2-indicatorSeparator {
  display: none;
}
.pickup-instruction-select-box .css-1xc3v61-indicatorContainer {
  padding-top: 0;
  padding-bottom: 0;
}

.pickup-ins-meeting-point-section {
  margin-top: 27px;
}
.meeting-point-card {
  background: #e7ebee87;
  width: 100%;
  padding: 21px 26px 46px 24px;
  border-radius: 8px;
}
.meeting-point-card input {
  border: 0.5px solid #00000057;
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  height: 36px;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  outline: none;
}
.pickup-instruction-section-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pickup-instruction-section-img img {
  width: 64px;
  height: 52px;
  border-radius: 4px;
}

.add-pickup-instruction-image {
  width: 265px;
  height: 120px;
  border-radius: 10px;
  border: 1px dashed #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-pickup-instruction-image-preview {
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.preview-cancel {
  position: absolute;
  top: 4px;
  right: 4px;
}
.add-pickup-instruction-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.add-pickup-instruction-image button {
  border: 1px solid #0f91d2b2;
  background-color: #fbfdfe;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  color: #0f91d2;
}
/* Availability */
.availability-select-box .css-13cymwt-control {
  min-height: 47px;
  border: 0.5px solid #000000;
  border-radius: 2px;
}
.availability-select-box .css-13cymwt-control:hover {
  border: 0.5px solid #29aee6 !important;
}
.availability-select-box .css-t3ipsp-control {
  min-height: 47px;
  border: 0.5px solid #29aee6 !important;
  box-shadow: none;
  border-radius: 2px;
}
.availability-select-box .css-1u9des2-indicatorSeparator {
  display: none;
}
.availability-select-box .css-1xc3v61-indicatorContainer {
  padding-top: 0;
  padding-bottom: 0;
}

/* Dispatcher */
.dispatcher-list-tbody tr td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.dispatcher-add-modal .MuiDialog-paperWidthSm {
  max-width: 617px;
}
.dispatcher-add-modal .MuiDialog-paperScrollPaper {
  width: 617px;
  /* min-height: 503px; */
}
.dispatcher-list-modal .MuiDialog-paperWidthSm {
  max-width: 1055px !important;
}
.dispatcher-selection-box .css-1293zam-control {
  height: 47px;
  border: 0.5px solid #00000059;
  border-radius: 2px;
}
.dispatcher-selection-box .css-1u9des2-indicatorSeparator {
  display: none;
}
.dispatcher-add-profile-img {
  margin-top: 24px;
  margin-bottom: 20px;
}
.dispatcher-add-profile-img img {
  margin-top: 16px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.dispatcher-table-img {
  display: flex;
  align-items: center;
}
.dispatcher-table-img img {
  margin-right: 7px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
}
/* campaign-table-section */
.campaign-table-section table thead tr th {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.campaign-table-section-first-cell {
  width: 94px;
  text-wrap: wrap !important;
  display: block;
}
.campaign-select select {
  /* all: unset; */
  outline: none;
  /* border: 0.5px solid #000000; */
  border: 0.2px solid #000000;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  height: 33px;
  width: 100%;
  border-radius: 2px;
  /* padding: 8px 11px; */
  padding-left: 11px;
  padding-right: 11px;
}

/* Analysis List  */
.analysis-card-md {
  box-shadow: 0px 4px 20px -5px #00000040;
  width: 265px;
  height: 93px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 14px 12px 18px 18px;
}
.badge-menu {
  padding: 3.5px 6px;
  background: #24a7e912;
  color: #29aee6;
  padding: 2px;
}
.badge-menu p {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
}
.analysis-dashboard-no-poly {
  margin-top: 20px;
  padding: 15px 18px;
  box-shadow: 0px 4px 20px -5px #00000040;
}
.analysis-dashboard-no-pia {
  padding: 15px 18px;
  box-shadow: 0px 4px 20px -5px #00000040;
}
.analysis-list-table thead th {
  font-size: 12px;
  text-wrap: nowrap;
}
.analysis-list-table thead th {
  font-size: 12px;
}
.analysis-list-table tbody tr td:nth-child(1) {
  font-size: 12px;
  font-weight: 600;
}

.chart-left-section {
  width: 70%;
}
.chart-right-section {
  width: 30%;
}

.booking-value-progress p {
  margin: 0;
  display: flex;
  align-items: center;
}

.booking-value-progress span {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 14px;
}
/* Campagne */
.campaign-add-modal .MuiDialogTitle-root {
  padding: 18px 21px 9px 37px;
}
.campaign-add-modal .MuiDialogTitle-root h6 {
  font-size: 17px;
  font-weight: 500;
}
.campaign-add-modal .MuiDialog-paperWidthSm {
  max-width: 744px;
}
.campaign-add-modal .MuiDialog-paperScrollPaper {
  width: 744px;
  min-height: 794px;
}

/* Offer */
.offer-add-modal .MuiDialogTitle-root {
  padding: 18px 21px 9px 37px;
}
.offer-add-modal .MuiDialogTitle-root h6 {
  font-size: 17px;
  font-weight: 500;
}
.offer-add-modal .MuiDialog-paperWidthSm {
  max-width: 744px;
}
.offer-add-modal .MuiDialog-paperScrollPaper {
  width: 744px;
  min-height: 637px;
}

/* service level page css style */
.sl-rate-section {
  width: 118px;
  height: 34px;
  border: 0.2px solid #cacaca;
  border-radius: 4px;
  padding-right: 10px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.sl-rate-section input {
  border: none;
  outline: none;
  width: 30px;
  padding-left: 1px;
  /* border: 1px solid red; */
}
.sl-rate-section button {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.sl-rate-section button:nth-child(1) {
  color: #428702;
}
.sl-rate-section button:nth-child(1) {
  color: #fc0808;
}

.auto-driver-info{
 max-width: 100%;

}
.auto-driver-name-style {
  width: 100%;
}
/* Auto Driver Event */
.auto-driver-event-driver-detail {
  box-shadow: 0px 2px 10px -2px #00000040;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 15px 15px;
}

.select-arrow{
  font-size:40px;
 
}
.select-arrow::-ms-expand{
  font-size:40px;
  height: 40px;
  width: 40px;
}

/* Optional: Remove arrow in IE */
.no-arrow::-ms-expand {
  display: none;
}

.auto-driver-event-driver-detail input {
  outline: none;
  border: none;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  color: #000;
}
.auto-driver-spacing-spacing {
  margin-top: 16px;
  padding: 28px 27px;
}
.auto-driver-field-spacing-spacing {
  padding: 27px 22px;
}
/* City Airport Detail */

.airport-banner-detail-section {
  box-shadow: 0px 4px 20px -5px #00000040;
  background: linear-gradient(241.79deg, #29aee6 32.54%, #176180 99.68%);
  height: 250px;
  width: 506px;
  /* overflow: hidden; */
  border-radius: 10px;
  padding: 23px 18px 0px 29px;
}
.airport-banner-detail-section .logo-image {
  width: 102px;
  height: 18px;
}

.airport-activities-section {
  width: 337px;
  height: 475px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px 25px 17px 20px;
}

.airport-activities-section .activity-notification {
  /* display: flex; */
}
.airport-activities-section .activity-description {
  margin-top: 8px;
  padding: 10px;
  border: 0.5px solid #00000024;
  border-radius: 7px;
}
.activity-notification-line {
  border: 1px solid #0000000d;
  /* width: 1px; */
  flex: 1 1;
}

.airport-detail-poly-map {
  position: relative;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 522px;
  border-radius: 6px;
}

.airport-detail-poly-map-detail {
  position: absolute;
  top: 12px;
  left: 30px;
  z-index: 5;
}

.poly-modal .MuiDialog-paper {
  overflow: visible;
}
.poly-modal .MuiDialog-paperScrollPaper {
  width: 449px;
  height: 230px;
}
.add-polygon-main-section {
  display: flex;
  margin-top: 18px;
}
.polygon-section-area {
  /* position: relative; */
  padding: 13px;
}

.polygon-list-box {
  /* z-index: 10; */
  width: 334px;
  /* position: absolute; */
  padding: 25px 16px;
  background-color: #fff;
  /* top: 26px;
  left: 26px; */
  border-radius: 4px;
  margin-right: 19px;
}
.polygon-list-search {
  border: 0.5px solid #0000007d;
  height: 23px;
  border-radius: 6px;
  margin-top: 20px;
  width: 100%;
}
.polygon-list-search input {
  width: 100%;
}
.single-polygon {
  margin-top: 23px;
}
.custom-control-list-box {
  /* width: 43px;
  height: 43px; */
  background-color: #ffffff;
  padding: 5px 4px 3px 4px;
  border: 1px solid #cacaca;
  border-radius: 4px;
}
.polygon-label {
  background-color: #fff;
  width: -webkit-max-content !important;
  width: max-content !important;
  padding: 8px !important;
  height: 36px !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}
.add-poly-section {
  position: absolute;
  top: 12px;
  right: 30px;
  z-index: 5;
}
.add-poly-section div {
  width: 76px;
  height: 76px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Pickup timings */
.header-description-section.headerdescription_pickuptimings_selectdays {
  margin-top: 60px;
  margin-bottom: 35px;
}
.pickup-timing-section {
  padding: 28px 60px 52px 35px;
}

.pickup-time-picker {
  width: 100%;
}
.pickup-time-picker .react-datetime-picker__wrapper {
  border: 0.2px solid #000000;
  height: 31px;
  border-radius: 2px;
}
/* .pick-time-days-field .css-b62m3t-container {
  height: 31px;
}
.pick-time-days-field .css-1ad9n0n-control {
  height: 31px !important;
  min-height: 34px !important;
}
.pick-time-days-field .css-betqko-ValueContainer {
  height: 31px !important;

} */

/* Vehicle */

.vehicle-list-section {
  position: relative;
}
.info-icon-style {
  margin-top: 10px;
}

.vehicle-list-section .selected-vehicle-list {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.vehicle-list-section .selected-vehicle-list h4 {
  font-size: 21px;
  font-weight: 600;
  margin-left: 12px;
}
.vehicle-list-section .selected-vehicle-list img {
  width: 53.78px;
  height: 37px;
  object-fit: cover;
}
.vehicle-list-section .list-of-services {
  position: absolute;
  top: 44px;
  left: -76px;
  box-shadow: 0px 22px 57px 0px #0000001a;
  padding: 13px;
  z-index: 20;
  background-color: #ffffff;
  border-radius: 6px;
}
.vehicle-list-section .list-of-services ul {
  padding: 0;
  margin: 0;
}
.vehicle-list-section .list-of-services ul li {
  list-style: none;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 239px;
}
.vehicle-list-section .list-of-services ul li img {
  width: 30.52px;
  height: 21px;
}
.vehicle-list-section .list-of-services ul li p {
  margin-left: 13px;
  font-size: 12px;
  font-weight: 500;
}
.vehicle-list-section .list-of-services .selected {
}
.vehicle-list-section .list-of-services .unselected {
  background-color: #f1f1f1;
}

/* tab-selection */
.tab-selection-body {
  display: flex;
}
.tab-selection-body .tab-menu-title {
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 400;
  margin-right: 2px;
  color: #808a98;
  cursor: pointer;

  position: relative;
}
.tab-selection-tooltip-icon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.tab-selection-body .tab-menu-title-active {
  color: #29aee6;
  border-bottom: 2px solid #29aee6;
}
.tab-selection-body .tab-menu-title-dactive {
  color: #808a98;
}
.tab-selection-body .tab-menu-disabled {
  opacity: 0.6;
  pointer-events: none;
}

/* Modal for all */
.api-key-modal-header-section .MuiDialogTitle-root {
  display: flex;
  align-items: center;
}
.api-key-modal-header-section .MuiDialogTitle-root h6 {
  font-size: 17px;
  font-weight: 500;
  line-height: 20.57px;
}
.api-key-modal-header-section .MuiDialogTitle-root button {
  padding-top: 4px;
  padding-bottom: 0px;
}
/* Common Modal Header */
.header-modal-section .MuiDialogTitle-root {
  display: flex;
  align-items: center;
}
.header-modal-section .MuiDialogTitle-root h6 {
  font-size: 17px;
  font-weight: 500;
  line-height: 20.57px;
  color: #000000;
}
.header-modal-section .MuiDialogTitle-root button {
  padding-top: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  color: #737373;
}

.network-module-fields input,
.network-module-fields select {
  outline: none;
  border: 0.2px solid #000000;
  background: #ffffff;
  height: 34px;
  width: 100%;
  border-radius: 2px 0px 0px 0px;
  padding-left: 10px;
  border-radius: 3px;
}

.add-network-modal .MuiDialog-paper {
  width: 100%;
  max-width: 800px;
}
/* Input Field */
 
.input-field-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.input-field-label {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 17px;
  padding-bottom: 14px;
  color: black;
}

.input-field-tooltip {
  cursor: pointer;
  margin-left: 8px;
}

.input-field-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 6px 16px;
  background: white;
}

.input-field-container.rounded-none {
  border-radius: 0;
}

.input-field-container.rounded {
  border-radius: 8px;
}

.input-field-container.rounded-md {
  border-radius: 6px;
}

.input-field-container.rounded-sm {
  border-radius: 4px;
}

.input-field-container.disabled {
  background-color: #f1f1f1;
  border: none;
}

.input-field-container.multiline {
  padding-top: 8px;
}

.input-field-textarea {
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  font-family: "Inter", sans-serif;
  resize: none;
}

.input-field-input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

/* Date Range  */
.date-time-field-css .rdrDateDisplayWrapper {
  display: none;
}
.date-time-field-css .rdrDay {
  width: calc(100% / 7);
  /* height: 34px; */
}
.date-time-field-css .rdrSelected {
  background-color: #2ca9dd !important;
  left: 5px;
  right: 5px;
  /* border-radius: 100%; */
}
.date-time-field-css .rdrDayrdrDayHovered {
  height: 30px;
  border-radius: 50px;
  border-color: #000000 !important;
}
.date-time-field-css .rdrDayToday .rdrDayNumber span:after {
  display: none;
}
.date-time-field-css .rdrDayEndPreview {
  color: #2ca9dd !important;
}
.date-time-field-css .rdrDayEndPreview,
.date-time-field-css .rdrDayNumber {
  left: 3px;
}
.date-time-field-css .rdrDayStartPreview,
.date-time-field-css .rdrDayNumber {
  right: 3px;
}
/* .date-time-field-css .rdrDayEndOfWeek .rdrDayStartPreview {
  right: 3px;
} */
/* .date-time-field-css .rdrDayStartOfWeek .rdrDayEndPreview {
  left: 3px;
} */
.date-time-field-css .rdrDayStartOfMonth .rdrDayInPreview,
.date-time-field-css .rdrDayStartOfMonth .rdrDayEndPreview,
.date-time-field-css .rdrDayStartOfWeek .rdrDayInPreview,
.date-time-field-css .rdrDayStartOfWeek .rdrDayEndPreview {
  left: 3px;
}
.date-time-field-css .rdrDayEndOfMonth .rdrDayInPreview,
.date-time-field-css .rdrDayEndOfMonth .rdrDayStartPreview,
.date-time-field-css .rdrDayEndOfWeek .rdrDayInPreview,
.date-time-field-css .rdrDayEndOfWeek .rdrDayStartPreview {
  right: 3px;
}

/*  */

.date-time-field-css .rdrMonth {
  width: 275px;
  padding-bottom: 11px;
}
/* .rdrNextPrevButton  .rdrNextPrevButton */
.date-time-field-css .rdrNextPrevButton i,
.date-time-field-css .rdrNextButton i {
  display: none;
}
.date-time-field-css .rdrNextPrevButton,
.date-time-field-css .rdrNextButton {
  /* border: 1px solid black; */
  background-color: transparent;
  border-radius: 50px;
  position: relative;
}
.date-time-field-css .rdrNextPrevButton::after {
  content: url(/static/media/calendar_arrow-left.65e0ce7f.svg);
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  /* background-color: #0e97ac; */
}
.date-time-field-css .rdrNextButton::after {
  content: url(/static/media/calendar_arrow-right.e9710e5a.svg);
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
}
.date-time-field-css {
  position: relative;
}

.input-container-2 {
  display: flex;
  align-items: center;
  position: relative;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
}

.calendar-icon {
  font-size: 22px;
  color: black;
  margin-left: 10px;
}

.date-range-container {
  position: absolute;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 22px 57px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px;
}

.date-range-container .rdrDay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}

.date-range-container .rdrDay span {
  color: black;
}

.date-range-container .rdrDayWeekend span {
  color: #2ca9dd;
}

.date-range-container .rdrDaySelected span {
  color: white;
  background-color: #2ca9dd;
  border-radius: 50%;
}
.GoogleMap{
  height: '100vh'; 
  width: '100%';
}
/*Responsive*/
@media  screen and (max-width:768px) {

  .auto-driver-info{
    display:contents;
  }
  .auto-driver-event-driver-detail{
    margin-bottom: 10px;
    margin-top: 10px;
    width:auto;
    padding: 5px 5px
  }
  /* .auto-driver-event-driver-detail input {
    margin-left: 0px;
  } */
  .makeStyles-container-60 {
    width: 50%;
  }
  .GoogleMap{
    height: 29vh;
  }
  .Sm-screen {
    justify-content: start;
    overflow: hidden;
  }
  .MuiButton-root{
    padding:0px;
  }
}
@media  screen and (max-width:700px) {
  .redPencilStyle {
    display:  none;
  }
}

#free-solo-2-demo-label{
    top: -6px!important;
}
.MuiFormControl-root{
    height: 40px!important;
}
#free-solo-2-demo{
    padding: 0px;
}
.landing-box {
  position: relative;
  background-image: url(/static/media/landing-background.628fa3a1.svg);
  background-size: cover;
  padding: 20px 10%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.landing-logo {
  width: 150px;
}
.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 40px;
  row-gap: 40px;
  margin-top: 45px;
  z-index: 2;
}
.landing-textbox {
  text-align: center;
  max-width: 820px;
}
.landing-main-heading {
  color: white;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
}
.landing-description {
  color: white;
  text-align: center;
  line-height: 26px;
}

.landing-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.landing-button {
  background: #2ca9dd;
  padding: 17px 30px;
  color: white;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.landing-privacy-button {
  border: 1px solid rgba(255, 255, 255, 0.39);
  padding: 17px 20px;
  color: white;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.landing-laptop-img {
  align-self: center;
  max-width: 100vw;
  margin-top: 20px;
}

.landing-footer {
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  flex-wrap: wrap;
}

.landing-footer a {
  color: whitesmoke;
}

@media screen and (max-width: 480px) {
  .landing-box {
    padding: 20px;
  }
  .landing-main-heading {
    font-size: 26px;
    line-height: 34px;
  }
  .landing-description {
    font-size: 14px;
    line-height: 20px;
  }
}

